// /**
//  * Layout component that queries for data
//  * with Gatsby's useStaticQuery component
//  *
//  * See: https://www.gatsbyjs.com/docs/use-static-query/
//  */

import * as React from "react"
import PropTypes from "prop-types"
import Header from "../Globals/Header/Header"
import Footer from "../Globals/Footer/Footer"
import SubscriptionForm from "../Globals/SubscriptionForm/SubscriptionForm"
import { get } from "../../helpers/utils"
import ScriptWrapper from "../Wrappers/ScriptWrapper/ScriptWrapper"
import PopupWrapper from "../Wrappers/PopupWrapper/PopupWrapper"

const Layout = ({ children, seoData, globalComponents }) => {
    const FooterProps = globalComponents?.find(component => component?.Footer)
    const HeaderProps = globalComponents?.find(component => component?.Header)
    const SubscriptionFormProps = globalComponents?.find(component => component?.SubscriptionForm)
    const footerCollection = get(FooterProps, "Footer.footerCollection.items[0]", {})
    const headerCollection = get(HeaderProps, "Header.headerCollection.items[0]", {})
    const subscriptionFormCollection = get(
        SubscriptionFormProps,
        "SubscriptionForm.subscriptionFormCollection.items[0]",
        {}
    )

    return (
        <>
            <ScriptWrapper seoHead={seoData} />

            <div media="print" className="main-container overflow-hidden relative">
                <Header {...headerCollection} />
                <main className="pt-[66px] md:pt-[77px]">{children}</main>
                <SubscriptionForm {...subscriptionFormCollection} />
                <Footer {...footerCollection} />
                {/* Based on the task RITM1452887, uncommented below popup code. Can be comment for later if needed. */}
                <div id="base-modal"></div>
                <PopupWrapper variant="POPUP_MODAL" />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    seoData: PropTypes.object,
    globalComponents: PropTypes.array,
}

export default Layout
